'use client'

import {
	ANCHOR_TAG_CLOSING,
	ANCHOR_TAG_OPENING,
	ANCHOR_TAG_OPENING_LEGACY,
} from '../../../constants/privacyPolicy'
import { SPLIT_STRING_BY_MATCHERS_ERROR_MESSAGE } from '../../../constants/splitStringByMatchers'
import { splitStringByMatchers } from '../shared/splitStringByMatchers'

/**
 * @example
 *
 * const stringToSplit = 'parte primera<MTCH1>, parte segunda,</MTCH2> parte tercera.
 * const [partePrimera, parteSegunda, parteTercera] = splitStringByMatchers(stringToSplit, '<MTCH1>', '</MTCH2>')
 *
 * console.log(partePrimera) // parte primera
 * console.log(parteSegunda) // , parte segunda,
 * console.log(parteTercera) //  parte tercera
 *
 */
export function clientSplitStringByMatchers(
	input: string
): [string, string, string] | null {
	const match = splitStringByMatchers(
		input,
		ANCHOR_TAG_OPENING,
		ANCHOR_TAG_CLOSING
	)

	const matchLegacy = splitStringByMatchers(
		input,
		ANCHOR_TAG_OPENING_LEGACY,
		ANCHOR_TAG_CLOSING
	)

	if (match) {
		return match
	}

	if (matchLegacy) {
		return matchLegacy
	}

	console.error(SPLIT_STRING_BY_MATCHERS_ERROR_MESSAGE)
	return null
}
