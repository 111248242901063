import { cx } from 'utils/cx'

import styles from '../ExpandableContent/ExpandableTextByLink.module.scss'

export function SimplePolicies({
	className,
	children,
}: {
	className?: string
	children: React.ReactElement
}) {
	return (
		<div className={cx(styles.expandableContent, className)}>
			<p className={styles.resetContent}>{children}</p>
		</div>
	)
}
