export function splitStringByMatchers(
	input: string,
	firstMatcher: string,
	secondMatcher: string
): [string, string, string] | null {
	const regexPattern = new RegExp(
		`^(.*?)(?:${firstMatcher})(.*?)(?:${secondMatcher})(.*)$`
	)
	const match = input.match(regexPattern)

	if (!match) {
		return null
	}

	const [, firstPart, secondPart, thirdPart] = match
	return [firstPart, secondPart, thirdPart]
}
