'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useFormContext } from 'react-hook-form'

import styles from './GenericError.module.scss'

interface BackInStockFormValues {
	email: string
}

export function GenericError(): React.ReactNode | null {
	const { t } = useLabels()
	const {
		formState: { errors },
	} = useFormContext<BackInStockFormValues>()

	if (!errors?.root) {
		return null
	}

	return <p className={styles.genericError}>{t('error.connection.title')}</p>
}
