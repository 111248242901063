export const EMAIL = {
	name: 'email',
	id: 'backInStock.emailInput',
}

export const SUBMIT_BUTTON_ID = 'backInStock.button.submit'

export const DATA_TEST_ID = {
	body: 'backInStock.dialog',
	backInStockForm: 'backInStock.form',
	emailInput: 'backInStock.emailInput',
	submitButton: 'backInStock.button.submit',
	gdpr: 'backInStock.privacyPolicy',
	gdprLink: 'backInStock.privacyPolicyLink.goToMangoGdpr',
	feedback: 'backInStock.feedback',
	feedbackOkButton: 'backInStock.feedbackButton.closeDialog',
	productName: 'backInStock.productName',
	modal: 'backInStock.modal',
}
