'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { ClientExpandablePolicies } from 'legal/components/ExpandablePolicies/client/ClientExpandablePolicies'
import { BACKINSTOCK_POLICY_ID } from 'legal/constants/privacyPolicy'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { ClientSimplePolicies } from '../../SimplePolicies/client'

const USA_ISO = 'US'

export const BackInStockLegalPolicy = () => {
	const { t } = useLabels()
	const {
		country: { countryISO },
	} = useMasterData()

	const isUsa = countryISO === USA_ISO

	if (isUsa) {
		return <ClientSimplePolicies text={t('notifyMe.rgpd.legal.text')} />
	}

	return (
		<ClientExpandablePolicies
			initialText={t('notifyMe.rgpd.legal.text')}
			title={t('notifyMe.rgpd.legal.modal.title')}
			body={t('notifyMe.rgpd.legal.modal.bodycopy')}
			id={BACKINSTOCK_POLICY_ID}
		/>
	)
}
