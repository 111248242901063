'use client'

import { clientSplitStringByMatchers } from '../../../utils/splitStringByMatchers/client/clientSplitStringByMatchers'
import { ExpandableContent } from '../../ExpandableContent/ExpandableContent'
import { ClientExpandedPolicy } from './ExpandedPolicy/ClientExpandedPolicy'

type ExpandablePoliciesProps = {
	body: string
	className?: string
	id: string
	initialText: string
	title: string
}

export function ClientExpandablePolicies({
	body,
	className,
	id,
	initialText,
	title,
}: ExpandablePoliciesProps) {
	const initialTextWithLink = initialText
	const match = clientSplitStringByMatchers(initialTextWithLink)

	if (!match) {
		return null
	}

	const [textBeforeTrigger, trigger, textAfterTrigger] = match

	return (
		<ExpandableContent
			className={className}
			id={id}
			contentBeforeTrigger={textBeforeTrigger}
			trigger={trigger}
			contentAfterTrigger={textAfterTrigger}
			contentToExpand={
				<ClientExpandedPolicy title={title} body={body} id={id} />
			}
		/>
	)
}
