'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { DATA_TEST_ID } from 'back-in-stock/constants'
import { BackInStockBody } from 'back-in-stock/src/components/BackInStockModal/BackInStockBody/BackInStockBody'
import { useBackInStockModalContext } from 'back-in-stock/src/hooks/useBackInStockModalContext/useBackInStockModalContext'
import type { BackInStockModalProps } from 'back-in-stock/src/types'
import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { BackInStockLegalPolicy } from 'legal/components/policies/BackInStockLegalPolicy/BackInStockLegalPolicy'
import { useEffect, useRef } from 'react'

import { GenericEvents } from '../../constants/analytics'

export function BackInStockModal({
	modalType = MODAL_TYPE.SHEET_SMALL,
}: BackInStockModalProps) {
	const modalRef = useRef<ModalAPI>(null)
	const { isOpen, closeProductSubscription } = useBackInStockModalContext()
	const { backInStockModalProps } = useBackInStockModalContext()

	useEffect(() => {
		if (isOpen && modalRef.current) {
			sendGenericEvent(GenericEvents.NOTIFY_ME_SHOW, {
				product_id: backInStockModalProps?.productId,
				color: backInStockModalProps?.productColorId,
				size: backInStockModalProps?.productSizeId,
			})
			modalRef.current.show()
		}

		if (!isOpen && modalRef.current) {
			modalRef.current.close()
		}
	}, [isOpen])

	return (
		<Modal
			name='back-in-stock-modal'
			ref={modalRef}
			type={modalType}
			title='back-in-stock-modal'
			data-testid={DATA_TEST_ID.modal}
			onClose={closeProductSubscription}
		>
			<BackInStockBody />
			<BackInStockLegalPolicy />
		</Modal>
	)
}
