'use client'

import { StatusCode } from 'types/statusCodes'
import { env } from 'utils/envManager'

import type { BackInStockSubscriptionServiceParams } from '../types'

const basePath = '/ws-newsletter-subscription-service/v1/back-in-stock'

const BACK_IN_STOCK_SERVICE_URL = new URL(
	basePath,
	env.NEXT_PUBLIC_SITE_BASE_URL
)

export const backInStockSubscriptionService = async (
	params: BackInStockSubscriptionServiceParams,
	locale: string
): Promise<void> => {
	const response = await fetch(BACK_IN_STOCK_SERVICE_URL.href, {
		headers: {
			'Content-Type': 'application/json',
			'Accept-Language': locale,
		},
		method: 'POST',
		body: JSON.stringify(params),
		next: { revalidate: 0 },
	})

	if (response.status !== StatusCode.CREATED) {
		throw Error(
			`BackInStock service error with status ${response.status} ${response.statusText}.`
		)
	}
}
